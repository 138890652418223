import { Component, Inject, Injector, OnInit, HostListener } from '@angular/core';
import { runInInjectionContext } from '@angular/core';
import { Meta } from '@angular/platform-browser';
import { ActivatedRoute, ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { TranslocoService } from '@jsverse/transloco';
import { NgHttpCachingService } from 'ng-http-caching';
import { combineLatest, of, Subject } from 'rxjs';
import { catchError, map, takeUntil } from 'rxjs/operators';

import { monitorLoginState, OktaInterfaceService } from '@shure/cloud/shared/okta/data-access';
import { oktaSessionGuard } from '@shure/cloud/shared/okta/data-access';
import { BreakpointService } from '@shure/cloud/shared/services/media-breakpoints';
import { SwitchOrganizationService } from '@shure/cloud/shared/switch-organization/feature-switch-organization';
import { CloseTextOption, SnackbarService } from '@shure/cloud/shared/ui/components';
import { APP_ENVIRONMENT, AppEnvironment } from '@shure/cloud/shared/utils/config';
import { LanguagesService } from '@shure/shared/angular/utils/i18n';
import { ILogger } from '@shure/shared/angular/utils/logging';

import { environment } from '../environments/environment';

@Component({
	selector: 'sh-app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
	public appVersion: string;
	public clientVersion: string | undefined;
	public showNotifications: boolean;

	public readonly isSmallDevice = this.breakpointService.isLteSmall;
	private destroy = new Subject<boolean>();

	constructor(
		private readonly languageService: LanguagesService,
		private metaTagService: Meta,
		public oktaIntf: OktaInterfaceService,
		private breakpointService: BreakpointService,
		@Inject(APP_ENVIRONMENT) private appEnv: AppEnvironment,
		private injector: Injector,
		private router: Router,
		private activatedRoute: ActivatedRoute,
		private orgSwitcherService: SwitchOrganizationService,
		private snackbarService: SnackbarService,
		private readonly translocoService: TranslocoService,
		private readonly logger: ILogger,
		private ngHttpCachingService: NgHttpCachingService
	) {
		this.logger = logger.createScopedLogger('CDM AppComponent');
		this.appVersion = this.appEnv.appVersion;
		this.clientVersion = this.appEnv.clientVersion;
		this.showNotifications = Boolean(this.appEnv.cdmFeatureFlags?.showNotificationsInHeader);

		monitorLoginState(oktaIntf, {
			onLogIn: this.onLogIn.bind(this),
			onLogOut: this.onLogOut.bind(this)
		});
	}

	//
	// The purpose of this code is to handle the case when a user has both
	// Shure Cloud tabs and CDM tabs opened, and then logs out of the Shure Cloud.
	// When the user clicks back into the CDM window, we'll check to see if the
	// session still exists, and if not logout the user.
	//
	// window:focus events are triggered only the first time the user clicks
	// in a window after having the cursor focused elsewhere.
	//
	@HostListener('window:focus', ['$event'])
	public onFocus(_event: FocusEvent): void {
		// the oktaSessionGuard must run in an injection context, which is why this
		// code isn't a simple function call.
		runInInjectionContext<void>(this.injector, () =>
			oktaSessionGuard(<ActivatedRouteSnapshot>{}, <RouterStateSnapshot>{})
		);
	}

	public ngOnInit(): void {
		/* no-op comment to trigger pipelin build */
		this.languageService.getPreferredCultureCode();
		if (environment.production) {
			this.metaTagService.addTags([{ name: 'robots', content: 'index' }]);
		} else {
			this.metaTagService.addTags([{ name: 'robots', content: 'noindex, nofollow' }]);
		}
	}

	private onLogIn(): void {
		this.ngHttpCachingService.clearCache();
		this.destroy = new Subject();
		if (!this.appEnv.cdmFeatureFlags?.showOrgSwitcher) {
			return;
		}
		const requestedOrgId$ = this.activatedRoute.queryParamMap.pipe(
			map((queryParamMap) => queryParamMap.get('orgId')),
			// remove possible leading/training quotes
			map((orgId) => orgId?.replace(/["']/g, '') ?? ''),
			takeUntil(this.destroy)
		);
		const currentOrgId$ = this.oktaIntf.getUserProfile$().pipe(
			map((userProfile) => userProfile.orgId),
			takeUntil(this.destroy)
		);
		const allOrgIds$ = this.orgSwitcherService.getAllMyOrganizations().pipe(
			map((orgData) => {
				const items = orgData.body.body?.items;
				if (!items) {
					return [];
				}
				return items.map((item) => item.id ?? '');
			}),
			catchError(() => of([])),
			takeUntil(this.destroy)
		);

		combineLatest([requestedOrgId$, currentOrgId$, allOrgIds$])
			.pipe(takeUntil(this.destroy))
			.subscribe({
				next: ([requestedOrgId, currentOrgId, allOrgIds]) => {
					this.checkAndSwitchOrgs(requestedOrgId, currentOrgId, allOrgIds);
				}
			});
	}

	private onLogOut(): void {
		this.destroy.next(true);
		this.destroy.complete();
	}

	private checkAndSwitchOrgs(requestedOrgId: string, currentOrgId: string, allOrgIds: string[]): void {
		// no requested org, can't switch
		if (!requestedOrgId || requestedOrgId.length === 0) {
			return;
		}

		// already on this org... no reason to switch
		if (requestedOrgId === currentOrgId) {
			// remove orgId query parameter
			this.router.navigate(['/']);
			return;
		}

		// if the requested Org Id is not a valid org for the user put up a snackbar,
		// then remove the orgId parameter from the URL by routing to '/' w/out the query parameter
		if (!allOrgIds.includes(requestedOrgId)) {
			this.snackbarService.open(
				this.translocoService.translate('cloud.shared.switch-org.not-a-member-of-org'),
				CloseTextOption.Dismiss
			);
			this.router.navigate(['/']);
			return;
		}

		// switch to requested Org
		this.logger.debug('Switching orgs', 'New value %o', requestedOrgId);
		this.oktaIntf
			.setOktaSessionTenant$(requestedOrgId, false)
			.pipe(
				map(() => true),
				catchError(() => {
					return of(false);
				}),
				takeUntil(this.destroy)
			)
			.subscribe((result: boolean) => {
				if (result) {
					// it was decided to not keep the orgId in the URL.
					// route to / to remove it, then reload the page.
					this.router.navigate(['/']).then(() => {
						window.location.reload();
					});
				}
			});
	}
}
