/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { GetAllOrganizationsResponse } from '../../models/get-all-organizations-response';

export interface GetOrganizations$Params {
  pageSize?: number;
  page?: number;
  fromDate?: string;
  toDate?: string;
}

export function getOrganizations(http: HttpClient, rootUrl: string, params?: GetOrganizations$Params, context?: HttpContext): Observable<StrictHttpResponse<GetAllOrganizationsResponse>> {
  const rb = new RequestBuilder(rootUrl, getOrganizations.PATH, 'get');
  if (params) {
    rb.query('pageSize', params.pageSize, {});
    rb.query('page', params.page, {});
    rb.query('fromDate', params.fromDate, {});
    rb.query('toDate', params.toDate, {});
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'application/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<GetAllOrganizationsResponse>;
    })
  );
}

getOrganizations.PATH = '/api/v2';
