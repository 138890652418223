import { AppEnvironment } from '@shure/cloud/shared/utils/config';
import { LogLevel } from '@shure/shared/angular/utils/logging';

export const environment: AppEnvironment = {
	production: false,
	remoteLogging: false,
	sessionInactivity: {
		idleWarnTime: 55 * 60,
		idleGracePeriod: 5 * 60
	},
	authorization: {
		clientId: '0oa15psf9prHkyIyi0h8',
		oktaDomain: 'iam.dev.shure.services',
		oktaDomainExt: 'accountssb.shure.com',
		authServerPath: '/oauth2/default',
		scopes: ['openid', 'profile', 'email', 'offline_access', 'shure_tenant'],
		enableSecondaryIDP: false,
		secondaryIDP: '0oa11drlvpkrHCQQK0h8'
	},
	useClassicEngine: false,
	sysApi: {
		url: 'https://system-api.dev.shure.services/graphql',
		apiKey: ''
	},
	orgs: {
		groupsApiUrl: 'https://orgs.dev.shure.services/groups',
		licensesApiUrl: 'https://orgs.dev.shure.services/licenses',
		orgsApiUrl: 'https://orgs.dev.shure.services/organizations',
		rolesApiUrl: 'https://orgs.dev.shure.services/roles',
		usersApiUrl: 'https://orgs.dev.shure.services/users',
		pemissionsApiUrl: 'https://orgs.dev.shure.services/permissions',
		notificationsApiUrl: 'https://notifications-api.dev.shure.services/api/v1',
		notificationsWebSocketUrl: 'wss://notifications-api-sockets.dev.shure.services',
		preferencesApiUrl: 'https://preferences-api.dev.shure.services/api/v1',
		featureRequestApiUrl: 'https://orgs.dev.shure.services/feature-requests',
		verificationCodeApiUrl: 'https://orgs.dev.shure.services/verification-code'
	},
	logLevel: LogLevel.Error,
	useMockup: false,
	appVersion: '1.0.15.0',
	clientVersion: 'X.X.X.X',
	showOnlyIdp: false,

	showMicStatus: true,
	showControlNet: true,
	showAudioNet: true,
	showAbout: true,
	showFirmwareUpgrade: true,
	showProxiedDevices: true,
	showTags: false,

	cdmFeatureFlags: {
		showNotificationsInHeader: false,
		showOrgSwitcher: true,
		showReboot: true
	},
	// non-cdm flags needed
	featureFlags: {
		changeOrganization: true
	},

	signInMethod: 'okta-hosted',
	signOutScope: 'signout-okta',

	shureCloudUriMap: [
		{
			urlPattern: 'services',
			shureCloudUri: 'https://ignite.dev.shure.services/'
		},
		{
			urlPattern: 'com',
			shureCloudUri: 'https://dev.cloud.shure.com/'
		},
		{
			urlPattern: 'localhost',
			shureCloudUri: 'http://localhost:4200/'
		}
	],
	appType: 'device',
	appRoles: ['organization-admin', 'device-admin', 'user-license-and-device-admin']
};
